// Generated by Framer (6124c77)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CpeSohLeF", "ki60B4WF5"];

const serializationHash = "framer-b7FbN"

const variantClassNames = {CpeSohLeF: "framer-v-6xtryv", ki60B4WF5: "framer-v-1gib5wd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "CpeSohLeF", Inverse: "ki60B4WF5"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "CpeSohLeF"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "CpeSohLeF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}}><Image {...restProps} as={"a"} background={{alt: "DOLL & Co. Architecture Design Logo", fit: "fit", intrinsicHeight: 51, intrinsicWidth: 185, pixelHeight: 199, pixelWidth: 750, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/D0eX5PtPt6ZtP2uxADL2olmJ3o.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/D0eX5PtPt6ZtP2uxADL2olmJ3o.png?scale-down-to=512 512w,https://framerusercontent.com/images/D0eX5PtPt6ZtP2uxADL2olmJ3o.png 750w"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-6xtryv", className, classNames)} framer-1ypskfr`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"CpeSohLeF"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ki60B4WF5: {"data-framer-name": "Inverse", background: {alt: "DOLL & Co. Architects Logo", fit: "fit", intrinsicHeight: 197, intrinsicWidth: 712, pixelHeight: 199, pixelWidth: 750, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/78FcKUbrWVfK7ZoB6keigjsZNyg.png"}}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-b7FbN.framer-1ypskfr, .framer-b7FbN .framer-1ypskfr { display: block; }", ".framer-b7FbN.framer-6xtryv { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; min-height: 31px; min-width: 85px; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-b7FbN.framer-6xtryv { gap: 0px; } .framer-b7FbN.framer-6xtryv > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-b7FbN.framer-6xtryv > :first-child { margin-left: 0px; } .framer-b7FbN.framer-6xtryv > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 85
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ki60B4WF5":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLTYLc1ufz: React.ComponentType<Props> = withCSS(Component, css, "framer-b7FbN") as typeof Component;
export default FramerLTYLc1ufz;

FramerLTYLc1ufz.displayName = "Logo";

FramerLTYLc1ufz.defaultProps = {height: 31, width: 85};

addPropertyControls(FramerLTYLc1ufz, {variant: {options: ["CpeSohLeF", "ki60B4WF5"], optionTitles: ["Default", "Inverse"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerLTYLc1ufz, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})